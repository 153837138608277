<script>
export default {
  props: {
    externalCurrentPage: {
      type: Number,
      default: 0
    },
    itemCount: {
      type: Number,
      default: 0
    },
    initialItemsPerPage: {
      type: Number,
      default: 20
    },
    pageOptions: {
      type: Array,
      default: () => [20, 50, 100, 250]
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    pageChange: null
  },
  data() {
    return {
      idSuffix: Math.round(Math.random() * 1000),
      currentPage: this.externalCurrentPage ?? 1,
      itemsPerPage: this.initialItemsPerPage,
      manualPageNumber: null
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.itemCount / this.itemsPerPage);
    },
    displayedPages() {
      let current = this.currentPage;
      let last = this.numberOfPages;
      let delta = 2;
      let range = [];
      let rangeWithDots = [];
      let l;

      for (let i = 1; i <= last; i++) {
        if (
          i == 1 ||
          i == last ||
          (i >= current - delta && i < current + delta + 1)
        ) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          }
          else if (i - l !== 1) {
            rangeWithDots.push(null);
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    startItem() {
      return this.$data.itemsPerPage * (this.$data.currentPage - 1);
    }
  },
  watch: {
    externalCurrentPage(newVal) {
      this.$data.currentPage = newVal;
    },
    initialItemsPerPage(newVal) {
      this.$data.itemsPerPage = newVal;
    },
    itemsPerPage(newItemsPerPage, oldItemsPerPage) {
      if (newItemsPerPage === oldItemsPerPage) {
        return;
      }

      let currentStart = (this.$data.currentPage - 1) * oldItemsPerPage;
      let newPage = Math.floor(currentStart / newItemsPerPage) + 1;

      this.handlePageSelection(newPage);
    }
  },
  methods: {
    handleGoToPage() {
      if (!/^\d+$/.test(this.$data.manualPageNumber)) {
        this.$data.manualPageNumber = null;
        return;
      }

      let pageAsInt = parseInt(this.$data.manualPageNumber);
      if (pageAsInt < 1 || pageAsInt > this.numberOfPages) {
        this.$data.manualPageNumber = null;
        return;
      }

      this.handlePageSelection(pageAsInt);
    },
    handlePageSelection(pageNum, noEmit = false) {
      if (!pageNum) {
        return;
      }

      this.$data.currentPage = pageNum;

      if (!noEmit) {
        this.$emit(
          'pageChange',
          this.$data.itemsPerPage,
          this.startItem,
          this.$data.currentPage
        );
      }
    }
  }
};
</script>

<template>
  <div class="pagination-wrapper">
    <div v-if="itemCount > pageOptions[0]">
      <div class="cmp-pagination mb-2">
        <div class="mx-auto">
          <button
            v-if="!compact"
            class="btn btn-link"
            :disabled="currentPage == 1"
            @click="handlePageSelection(1)"
          >
            &laquo; First
          </button>
          <button
            class="btn btn-link"
            :disabled="currentPage == 1"
            @click="handlePageSelection(currentPage - 1)"
          >
            <span class="fas fa-chevron-left" /> Prev
          </button>
          <button
            v-for="page in displayedPages"
            :key="page"
            class="btn"
            :class="{ 'btn-link': currentPage != page, current: currentPage == page }"
            :disabled="!page"
            @click="handlePageSelection(page)"
          >
            {{ page || '&hellip;' }}
          </button>
          <button
            class="btn btn-link"
            :disabled="currentPage == numberOfPages"
            @click="handlePageSelection(currentPage + 1)"
          >
            Next <span class="fas fa-chevron-right" />
          </button>
          <button
            v-if="!compact"
            class="btn btn-link"
            :disabled="currentPage == numberOfPages"
            @click="handlePageSelection(numberOfPages)"
          >
            Last &raquo;
          </button>
        </div>
      </div>
      <div
        v-if="!compact"
        class="row gx-3 justify-content-center flex-nowrap"
      >
        <div class="col-auto">
          <div class="row gx-2 flex-nowrap">
            <label
              class="col-auto col-form-label col-form-label-sm"
              :for="`itemsPerPage-${idSuffix}`"
            >
              Items per page
            </label>
            <div class="col-auto">
              <select
                :id="`itemsPerPage-${idSuffix}`"
                v-model="itemsPerPage"
                class="form-select form-select-sm"
                style="width: 5.5em;"
              >
                <option
                  v-for="pageOption in pageOptions"
                  :key="pageOption"
                  :value="pageOption"
                >
                  {{ pageOption }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="row gx-2 flex-nowrap">
            <label
              :for="`goToPage-${idSuffix}`"
              class="col-auto col-form-label col-form-label-sm"
            >
              Go to page
            </label>
            <div class="col-auto">
              <input
                :id="`goToPage-${idSuffix}`"
                v-model="manualPageNumber"
                type="text"
                class="form-control form-control-sm"
                style="width: 3em;"
                @keyup.enter="handleGoToPage"
              >
            </div>
            <div class="col-auto">
              <button
                class="btn btn-sm btn-outline-primary"
                @click="handleGoToPage"
              >
                Go
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.pagination-wrapper {
  .cmp-pagination {
    text-align: center;

    .btn {
      margin-left: 0.1rem;
      margin-right: 0.1rem;

      &:not(.current) {
        &:hover {
          border: 1px solid $primary;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .btn-link {
      text-decoration: none;
    }

    .current {
      cursor: default;
      color: $white;
      background-color: $primary;

      &:hover {
        color: $white;
      }
    }
  }
}
</style>
