<script>
import BannerStack from '../common/banner-stack.vue';
import LoadingSpinner from '../common/loading-spinner.vue';
import PaginationArea from '../common/pagination-area.vue';
import ComicList from './comic-list.vue';
import UploadDialog from './upload-dialog.vue';
import Axios from 'axios';
import debounce from "lodash.debounce";
import { decode } from 'html-entities';
import { nextTick } from 'vue';
import { useCookies } from "vue3-cookies";
export default {
  components: {
    ComicList,
    UploadDialog,
    PaginationArea,
    BannerStack,
    LoadingSpinner,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      errorLoading: false,
      pages: [],
      chapters: [],
      totalPages: 0,
      itemsDisplayed: 20,
      currentPage: 1,
      editingPage: null,
      loadingDialog: false,
      sortDirection: 'desc',
      searchInputValue: "",
      searchText: "",
      searchLoadRequests: {},
      pageLoadRequests: {},
      loadingPages: false,
      loadingSearchResults: false,
    };
  },
  computed: {
    comicPairs() {
      let pairs = [];
      for (let i = 0; i < this.pages.length; i += 2) {
        let pair = [{ page: this.pages[i], index: i }];

        if (i + 1 < this.pages.length) {
          pair.push({ page: this.pages[i + 1], index: i + 1 });
        }

        pairs.push(pair);
      }
      return pairs;
    },
    hasActiveSearch() {
      return this.searchText.length >= 3;
    }
  },
  watch: {
    sortDirection(oldValue, newValue) {
      if (oldValue == newValue) {
        return;
      }

      if (this.hasActiveSearch) {
        this.loadSearchResults();
      }
      else {
        this.loadPages();
      }

      this.cookies.set("pg_sort", newValue, "30d");
    },
    searchInputValue: debounce(function(newValue) {
      this.searchText = newValue;
    }, 150),
    searchText(oldValue, newValue) {
      if (oldValue == newValue) {
        return;
      }

      // Don't execute a search if the query is present but fewer than three characters.
      if (!this.hasActiveSearch) {
        Object.keys(this.$data.searchLoadRequests).forEach((cancelRequestId) => {
          this.$data.searchLoadRequests[cancelRequestId].abort();
        });
        this.$data.loadingSearchResults = false;
      }

      // Resetting the page count will trigger a new search or reload the pages, depending on if there is a search query.
      this.handlePageChange(this.itemsDisplayed, 0, 1);
    },
  },
  created() {
    const url = new URL(window.location);
    let count = url.searchParams.get('c');
    let searchQuery = url.searchParams.get('q');

    if (count) {
      this.$data.itemsDisplayed = parseInt(count);
    }
    else {
      this.$data.itemsDisplayed = 20;
    }

    if (searchQuery) {
      this.$data.searchText = searchQuery;
    }
  },
  mounted() {
    let sortCookie = this.cookies.get("pg_sort");
    if (sortCookie == 'asc' || sortCookie == 'desc') {
      this.$data.sortDirection = sortCookie;
    }

    this.updatePageFromQuery();
    window.addEventListener('popstate', () => this.updatePageFromQuery());
  },
  methods: {
    updatePageFromQuery() {
      const url = new URL(window.location);
      let page = url.searchParams.get('page');

      if (page) {
        this.$data.currentPage = parseInt(page);
      }
      else {
        this.$data.currentPage = 1;
      }

      this.loadPages();
    },
    handleDelete(index) {
      let id = this.$data.pages[index].id;

      Axios.delete(`/api/pages/${id}`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.pages.splice(index, 1);
        })
        .catch((error) => {
          let errorMessage = "Failed to delete page. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    handleHide(index) {
      let id = this.$data.pages[index].id;

      Axios.put(`/api/pages/${id}/hide`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.pages[index].hidden = true;
        })
        .catch((error) => {
          let errorMessage = "Failed to hide page. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    handleShow(index) {
      let id = this.$data.pages[index].id;

      Axios.put(`/api/pages/${id}/show`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.pages[index].hidden = false;
        })
        .catch((error) => {
          let errorMessage = "Failed to show page. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    async showUploadDialog() {
      // Reload chapters so we have the latest list.
      this.loadChapters();

      await nextTick();
      this.$refs.uploadDialog.showModal();
    },
    handleAdd() {
      this.$data.editingPage = null;
      this.showUploadDialog();
    },
    handleEdit(index) {
      this.$data.loadingDialog = true;
      this.reloadPage(index);
    },
    handleEditReloadCallback(index, success) {
      this.$data.loadingDialog = false;

      if (!success) {
        return;
      }

      this.$data.editingPage = this.$data.pages[index];
      this.showUploadDialog();
    },
    handleDialogComplete() {
      this.$refs.mainBanners.add({
        class: 'success',
        timeout: 5000,
        message: `Page ${this.$data.editingPage ? 'updated' : 'created'} successfully.`
      });

      if (this.hasActiveSearch) {
        this.loadSearchResults();
      }
      else {
        this.loadPages();
      }
    },
    handlePageChange(numberDisplayed, offset, pageNum) {
      // Don't remember the pagination settings when searching.
      if (!this.hasActiveSearch && (pageNum != this.$data.currentPage || numberDisplayed != this.$data.itemsDisplayed)) {
        const url = new URL(window.location);
        url.searchParams.set('page', pageNum);
        url.searchParams.set('c', numberDisplayed);
        window.history.pushState({ page: pageNum, count: numberDisplayed }, '', url);
      }

      this.$data.itemsDisplayed = numberDisplayed;

      this.$data.currentPage = pageNum;

      if (this.hasActiveSearch) {
        this.loadSearchResults();
      }
      else {
        this.loadPages();
      }
    },
    handleClearSearch() {
      this.$data.searchText = "";
      this.$data.searchInputValue = "";
    },
    reloadPage(index) {
      let pageToReload = this.$data.pages[index];
      Axios.get('/api/pages/' + encodeURIComponent(pageToReload.id))
        .then((response) => {
          let reloadedPage = response.data;
          reloadedPage.transcript = decode(reloadedPage.transcript);
          reloadedPage.headerHtml = decode(reloadedPage.headerHtml);
          reloadedPage.footerHtml = decode(reloadedPage.footerHtml);
          reloadedPage.htmlContent = decode(reloadedPage.htmlContent);
          reloadedPage.htmlHeadContent = decode(reloadedPage.htmlHeadContent);
          this.$data.pages[index] = reloadedPage;

          this.$refs.mainBanners.clearType('danger');
          this.handleEditReloadCallback(index, true);
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load page data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });

          this.handleEditReloadCallback(index, false);
        });
    },
    loadPages() {
      const requestId = crypto.randomUUID();

      Object.keys(this.$data.pageLoadRequests).forEach((cancelRequestId) => {
        this.$data.pageLoadRequests[cancelRequestId].abort();
      });

      this.loadPagesInternal(requestId);
    },
    loadPagesInternal(requestId) {
      this.$data.pageLoadRequests[requestId] = new AbortController();
      this.$data.loadingPages = true;

      Axios.get('/api/pages?page=' + encodeURIComponent(this.$data.currentPage) + '&count=' + encodeURIComponent(this.$data.itemsDisplayed) + '&sort=' + encodeURIComponent(this.$data.sortDirection), {
        signal: this.$data.pageLoadRequests[requestId].signal
      })
        .then((response) => {
          this.$data.pages = response.data.pages.map(page => {
            page.transcript = decode(page.transcript);
            page.headerHtml = decode(page.headerHtml);
            page.footerHtml = decode(page.footerHtml);
            page.htmlContent = decode(page.htmlContent);
            page.htmlHeadContent = decode(page.htmlHeadContent);
            return page;
          });

          this.$data.totalPages = response.data.pagesAvailable;

          this.$refs.mainBanners.clearType('danger');
          this.$data.loadingPages = false;
          this.loadChapters();
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            return;
          }

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load page data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
          this.$data.loadingPages = false;
        })
        .finally(() => {
          delete this.$data.pageLoadRequests[requestId];
        });
    },
    loadSearchResults() {
      const requestId = crypto.randomUUID();

      Object.keys(this.$data.searchLoadRequests).forEach((cancelRequestId) => {
        this.$data.searchLoadRequests[cancelRequestId].abort();
      });

      this.loadSearchResultsInternal(requestId);
    },
    loadSearchResultsInternal(requestId) {
      this.$data.searchLoadRequests[requestId] = new AbortController();
      this.$data.loadingSearchResults = true;

      Axios.get('/api/pages/search?page=' + encodeURIComponent(this.$data.currentPage) + '&count=' + encodeURIComponent(this.$data.itemsDisplayed) + '&sort=' + encodeURIComponent(this.$data.sortDirection) + '&q=' + encodeURIComponent(this.$data.searchText), {
        signal: this.$data.searchLoadRequests[requestId].signal
      })
        .then((response) => {
          this.$data.pages = response.data.pages.map(page => {
            page.transcript = decode(page.transcript);
            page.headerHtml = decode(page.headerHtml);
            page.footerHtml = decode(page.footerHtml);
            page.htmlContent = decode(page.htmlContent);
            page.htmlHeadContent = decode(page.htmlHeadContent);
            return page;
          });

          this.$data.totalPages = response.data.pagesAvailable;

          this.$refs.mainBanners.clearType('danger');
          this.$data.loadingSearchResults = false;
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            return;
          }

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load search results. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
          this.$data.loadingSearchResults = false;
        })
        .finally(() => {
          delete this.$data.searchLoadRequests[requestId];
        });
    },
    loadChapters() {
      Axios.get('/api/chapters')
        .then((response) => {
          this.$data.chapters = response.data;
        });
    }
  }
};
</script>

<template>
  <h1>Comic Pages</h1>
  <banner-stack ref="mainBanners" />
  <div v-if="!errorLoading">
    <div class="row justify-content-between">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary mb-3"
          :disabled="hasActiveSearch"
          @click="handleAdd"
        >
          <span class="fas fa-plus" /> New Page
        </button>
        <upload-dialog
          ref="uploadDialog"
          :key="editingPage"
          :pages="pages"
          :chapters="chapters"
          :initial-page-data="editingPage"
          @upload="handleDialogComplete"
        />
      </div>
      <div class="col-auto">
        <div class="row gx-3">
          <div class="col-auto">
            <input
              v-model="searchInputValue"
              class="form-control"
              placeholder="Search"
            >
          </div>
          <div class="col-auto row gx-1">
            <div class="col-auto col-form-label">
              <label
                class="form-label"
                for="sortDirection"
              >
                Sort:
              </label>
            </div>
            <div class="col">
              <select
                id="sortDirection"
                v-model="sortDirection"
                class="form-select"
                style="width: 15em;"
              >
                <option value="desc">
                  Newest first
                </option>
                <option value="asc">
                  Oldest first
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingSpinner v-if="loadingPages || loadingSearchResults" />
    <div v-else>
      <div
        v-if="hasActiveSearch"
        class="search-result-text row gx-2 align-items-center"
      >
        <div class="col-auto">
          Search results for "{{ searchText }}"
        </div>
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="handleClearSearch"
          >
            Clear Search
          </button>
        </div>
      </div>
      <ComicList
        :pages="pages"
        :chapters="chapters"
        :is-search="hasActiveSearch"
        :buttons-disabled="loadingDialog"
        @delete="handleDelete"
        @edit="handleEdit"
        @hide="handleHide"
        @show="handleShow"
      />
      <pagination-area
        :external-current-page="currentPage"
        :item-count="totalPages"
        :initial-items-per-page="itemsDisplayed"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.comic-row {
  margin-bottom: 1rem;
}

.search-result-text {
  color: $gray-600;
  margin-bottom: 0.5rem;
}
</style>
