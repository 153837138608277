<script>
import ComicEntry from './comic-entry.vue';
export default {
  components: {
    ComicEntry,
  },
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    chapters: {
      type: Array,
      default: () => []
    },
    isSearch: Boolean,
    buttonsDisabled: Boolean,
  },
  emits: {
    delete: null,
    edit: null,
    hide: null,
    show: null,
  },
  computed: {
    comicPairs() {
      let pairs = [];
      for (let i = 0; i < this.pages.length; i += 2) {
        let pair = [{ page: this.pages[i], index: i }];

        if (i + 1 < this.pages.length) {
          pair.push({ page: this.pages[i + 1], index: i + 1 });
        }

        pairs.push(pair);
      }
      return pairs;
    }
  }
};
</script>

<template>
  <div>
    <div
      v-for="comicPair in comicPairs"
      :key="comicPair"
      class="row gx-3 mb-3"
    >
      <div class="col">
        <comic-entry
          :page-data="comicPair[0].page"
          :pages="pages"
          :chapters="chapters"
          :buttons-disabled="buttonsDisabled"
          @delete="$emit('delete', comicPair[0].index)"
          @edit="$emit('edit', comicPair[0].index)"
          @hide="$emit('hide', comicPair[0].index)"
          @show="$emit('show', comicPair[0].index)"
        />
      </div>
      <div class="col">
        <comic-entry
          v-if="comicPair.length > 1"
          :page-data="comicPair[1].page"
          :pages="pages"
          :chapters="chapters"
          :buttons-disabled="buttonsDisabled"
          @delete="$emit('delete', comicPair[1].index)"
          @edit="$emit('edit', comicPair[1].index)"
          @hide="$emit('hide', comicPair[1].index)"
          @show="$emit('show', comicPair[1].index)"
        />
      </div>
    </div>
    <div
      v-if="!pages.length"
      class="text-muted italic center"
    >
      {{ isSearch ? 'No results found' : 'No pages have been added. Click "New Page" to add one.' }}
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.comic-row {
  margin-bottom: 1rem;
}
</style>
