<template>
  <div class="loading-spinner">
    <span class="fas fa-circle-notch" />Loading&hellip;
  </div>
</template>

<style lang="scss">
  @import "resources/sass/_bs";
  .loading-spinner {
    font-size: 1.7em;
    margin-inline: auto;
    text-align: center;

    @keyframes spinner {
      to {transform: rotate(390deg);}
    }

    .fa-circle-notch {
      color: $blue-400;
      transform: rotate(30deg);
      animation: spinner 1s ease infinite;
      margin-inline-end: 0.5rem;
    }
  }
</style>
